import React from "react";

const Preview = ({ signature }) => {
  const filter = (item, field = "value") =>
    signature.filter((i) => i.name === item)[0][field];

  console.log("ee", filter("image", "src"));
  return (
    <div className="bg-indigo-50 select-none">
      <div className="container mx-auto p-5">
        <div className="rounded-lg shadow-lg p-2 w-2/4 bg-white select-all">
          <div id="signature">
            <img
              alt={filter("name") ?? ""}
              src={filter("image")}
              width={80}
              height={80}
              style={{
                fontFamily: "inherit",
                display: "inline-block",
                verticalAlign: "top",
                objectFit: "cover",
                borderRadius: "0.25rem",
                width: "5rem",
                height: "5rem",
                marginRight: "0.5rem",
              }}
            />
            <div style={{ fontFamily: "inherit", display: "inline-block" }}>
              <p
                style={{
                  color: "rgba(55, 65, 81, var(--tw-text-opacity))",
                  fontWeight: 700,
                  margin: 0,
                  fontFamily: "inherit",
                }}
              >
                {filter("name")}
              </p>
              <small
                style={{
                  fontFamily: "inherit",
                  color: "rgba(107, 114, 128, var(--tw-text-opacity))",
                  display: "inline-block",
                  marginBottom: "0.5rem",
                  fontSize: "80%",
                }}
              >
                {filter("position")}
              </small>
              <div style={{ fontFamily: "inherit", width: "100%" }}></div>
              <a
                style={{
                  fontFamily: "inherit",
                  color: "#6c7381",
                  fontSize: "0.75rem",
                  lineHeight: "1rem",
                  display: "block",
                  textDecoration: "none !important",
                }}
                href={`tel:${filter("phone")}`}
              >
                {filter("phone")}
              </a>
              <a
                style={{
                  fontFamily: "inherit",
                  color: "#6c7381",
                  fontSize: "0.75rem",
                  lineHeight: "1rem",
                  display: "block",
                  textDecoration: "none !important",
                }}
                href={`mailto:${filter("email")}`}
              >
                {filter("email")}
              </a>
              <a
                style={{
                  fontFamily: "inherit",
                  border: 0,
                  color: "#6c7381",
                  fontSize: "0.75rem",
                  lineHeight: "1rem",
                  display: "block",
                  textDecoration: "none !important",
                }}
                href={
                  !filter("website").includes("http")
                    ? `//${filter("website")}`
                    : filter("website")
                }
                target="_blank"
                rel="noreferrer"
              >
                {filter("website")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
