import React, { useState } from "react";

const Form = ({ setSignatureField, signatureFields }) => {
  const [copied, setCopied] = useState(false);

  const onSetSelection = ({ target }) => {
    target.setSelectionRange(0, -1);
  };

  const copySignature = () => {
    let range = document.createRange();
    range.selectNode(document.getElementById("signature"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    setCopied(() => true);
    setTimeout(() => {
      setCopied(() => false);
    }, 3000);
  };

  return (
    <div className="border-0 border-dashed border-t-4 border-blue-300 select-none">
      <div className="container mx-auto p-4 flex">
        <div className="flex-1 px-2">
          {signatureFields.map(({ name, value, type, accept }) => (
            <React.Fragment key={name}>
              <label htmlFor={name}>{name}</label>
              <input
                id={name}
                name={name}
                type={type ?? "text"}
                placeholder={name}
                className="border-2 p-1 block mb-4 w-full"
                onClick={!type ? (e) => onSetSelection(e) : null}
                onChange={setSignatureField}
                value={value}
                accept={accept ?? ""}
              />
            </React.Fragment>
          ))}
          <button
            className={`border-0 rounded text-white px-5 py-2 ${
              copied ? "bg-green-600" : "bg-indigo-500"
            }`}
            onClick={copySignature}
          >
            {copied ? "Copied to clipboard" : "Copy signature"}
          </button>
        </div>
        <div className="flex-1 px-2">
          <h1 className="text-xl text-indigo-600 font-bold mb-3">
            Create a FREE online signature
          </h1>
          <p className="mb-2">
            With this free tool you can easily create your own HTML email
            signature in a couple of minutes.
          </p>
          <p>
            Follow the below steps in order to create your own email signature.
          </p>
          <hr className="border-indigo-300 my-3" />
          <ol className="list-decimal ml-4">
            <li>Fill in your email signature info on the left</li>
            <li>Click on the copy signature button</li>
            <li>Go to the settings of your email provider</li>
            <li>Find the setting to edit the signature</li>
            <li>
              Press <code className="text-indigo-400">ctrl+v</code> or{" "}
              <code className="text-indigo-400">cmd+v</code> inside the
              signature input
            </li>
            <li>Save your changes</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Form;
